import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
      primary: {
        main: '#66a832',
        contrastText: '#ffffff',
    }
  }
});

export default theme;