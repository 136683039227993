import { 
    Card,
    MenuItem,
    TextField
} from '@material-ui/core';

import data from '../data.json';

import './monster.css';

function getTitleBonuses(monster, rank, ghost) {
    const free = {
        stuff: 0,
        special: 0,
        core: 0,
        spells: 0
    };

    const drops = {
        loot: 0,
        treasure: 0
    };

    if (monster.titles) {
        monster.titles.forEach((t) => {
            free.stuff += data.titles[t].stuff ? data.titles[t].stuff : 0;
            free.special += data.titles[t].special ? data.titles[t].special : 0;
            free.core += data.titles[t].core ? data.titles[t].core : 0;
            drops.loot += data.titles[t].loot ? data.titles[t].loot : 0;
            drops.treasure += data.titles[t].treasure ? data.titles[t].treasure : 0;
        })
    }

    if (monster.job) {
        free.stuff += data.jobs[monster.job].ranks[rank].stuff ? data.jobs[monster.job].ranks[rank].stuff : 0;
        free.special += data.jobs[monster.job].ranks[rank].special ? data.jobs[monster.job].ranks[rank].special : 0;
        free.spells += data.jobs[monster.job].ranks[rank].spells ? data.jobs[monster.job].ranks[rank].spells : 0;
    }

    if (ghost) {
        free.special += (data.ghosts[monster.type].special ? data.ghosts[monster.type].special : 0)
        free.stuff += (data.ghosts[monster.type].stuff ? data.ghosts[monster.type].stuff : 0)
        free.spells += (data.ghosts[monster.type].spells ? data.ghosts[monster.type].spells : 0)
    }

    return { free, drops }
}

function Monster({ details, rank, titles, ghost, onChange, onDelete }) {

    const { free, drops } = getTitleBonuses(details, rank, ghost);

    const stuff = details.stuff || [];
    const spells = details.spells || [];
    const tiers = ['starter'];
    let canIncrement = true;
    while (stuff.length < free.stuff) stuff.push('')
    while (spells.length < free.spells) spells.push('')

    free.core -= details.core ? details.core.length : 0;
    free.special -= details.special ? Object.values(details.special).reduce((acc, val) => acc + val, 0) : 0;

    if (free.core < 0) details.core = details.core.slice(0, details.core.length + free.core);

    data.ranks.forEach((r, idx) => {
        if (idx <= rank && r.tier) tiers.push(r.tier)
    })

    if (details.titles) {
        details.titles.forEach((t) => {
            if (data.titles[t].grantsTiers) {
                for (let tier of data.titles[t].grantsTiers) {
                    if (tiers.indexOf(tier) < 0) tiers.push(tier);
                }
            }
            console.log(titles);
            canIncrement = canIncrement && titles.indexOf(t) >= 0;
        })
    }

    const qty = details.qty || 1;

    const filteredTitles = titles
        .filter((t) => (!data.titles[t].ghost && !ghost) || (data.titles[t].ghost && ghost))
        .filter((t) => !details.titles || details.titles.indexOf(t) < 0)
        .filter((t) => tiers.indexOf(data.titles[t].tier) >= 0)

    return (
        <Card className={ 'monster' + (ghost ? ' ghost' : '')}>
            <h1>
                <TextField label="Name" value={ details.name ? details.name : '' } fullWidth
                    onChange={ (e) => onChange({name: e.target.value}) }
                />
                <TextField label="Quantity" inputProps={{ type: "number", min: 0 }} value={ qty } fullWidth
                    onChange={ (e) => {
                        if (parseInt(e.target.value) <= 0) {
                            onDelete();
                        } else {
                            if (parseInt(e.target.value) <= qty || canIncrement) {
                                onChange({ qty: parseInt(e.target.value) })
                            }
                        }
                    }}
                />
                <div className="print-only">{ details.name }{ qty !== 1 ? ' x ' + qty : '' }</div>
            </h1>
            { details.job || (ghost && details.type) ? <table className="core"><tbody>
                    <tr>{
                        ['strong','smart','tough','brave'].map((core) => {
                            return <th key={ core }>{ 
                                (details.job ? data.jobs[details.job].ranks[rank][core] : 0) + 
                                (ghost ? data.ghosts[details.type][core] : 0) +
                                (details.core ? details.core.filter((c) => c === core).length : 0)
                            }</th>
                        })
                    }</tr><tr>
                        <td>Strong</td>
                        <td>Smart</td>
                        <td>Tough</td>
                        <td>Brave</td>
                    </tr>
                </tbody></table>
            : null }
            { free.core > 0 &&  (
                <TextField label="Add Core Dice" value="" select fullWidth
                    onChange={ (e) => onChange({core: [...(details.core || []), e.target.value]})}
                >
                    <MenuItem value="strong">Strong</MenuItem>
                    <MenuItem value="smart">Smart</MenuItem>
                    <MenuItem value="tough">Tough</MenuItem>
                    <MenuItem value="brave">Brave</MenuItem>
                </TextField>
            )}
            { !ghost && <TextField label="Job" value={ details.job ? details.job : '' } select fullWidth
                onChange={ (e) => onChange({job: e.target.value}) }
            >{
                Object.keys(data.jobs).map((k) => (
                    <MenuItem value={ k } key={ k }>{ data.jobs[k].name }</MenuItem>
                ))
            }</TextField> }
            <div>
                { details.titles && details.titles.map((title, idx) => (<p key={ idx }>
                    <strong
                        key={ title }
                        onClick={ () => {
                            onChange({ titles: [...details.titles.filter((t) => title !== t) ] })
                        }}
                    >{ data.titles[title].name }{ data.titles[title].description ? ': ' : ''}</strong>
                    { data.titles[title].description }
                </p>))}
            </div>
            { free.special > 0 &&
                <TextField label="Select Special Dice" value="" select fullWidth
                    onChange={ (e) => onChange({
                        special: {
                            ...details.special,
                            [e.target.value]: (details.special && details.special[e.target.value] ? details.special[e.target.value] + 1 : 1)
                        }
                    })}
                >{
                    Object.keys(data.dice)
                        .filter((d) => (
                            !details.special ||
                            !details.special[d] || (
                                details.special[d] < 3 && (
                                    details.special[d] === 0 ||
                                    !data.dice[d].types ||
                                    data.dice[d].types.indexOf('solo') < 0
                                )
                            )
                        ))
                        .filter((d) => tiers.indexOf(data.dice[d].tier) >= 0)
                        .map((d) => (
                            <MenuItem key={ d } value={ d }>{ data.dice[d].name }</MenuItem>
                        ))
                }
                </TextField>
            }
            <div>
                { details.special && Object.entries(details.special).map((special) => (
                    <p key={ special[0] }>
                        <strong
                            onClick={ () => {
                                const newspecial = { ...details.special };
                                delete newspecial[special[0]];
                                onChange({ special: newspecial })
                            }}
                        >{ data.dice[special[0]].types.indexOf('solo') >= 0 ? null : special[1] } { data.dice[special[0]].name }: </strong>
                        { data.dice[special[0]].description}
                    </p>
                ))}
            </div>
            <div>
                { stuff.map((s, idx) => (
                    <div key={ idx }>
                        <TextField label="Stuff" value={ s } key={ idx } fullWidth
                            onChange={ (e) => {
                                onChange({ stuff: stuff.map((s, i) => i === idx ? e.target.value : s) })
                            }}
                        />
                        <p className="print-only">{ s }</p>
                    </div>
                ))}
            </div>
            <div>
                { spells.map((s, idx) => (
                    <div key={ idx }>
                        <TextField label="Spell" value={ s } fullWidth
                            onChange={ (e) => {
                                onChange({ spells: spells.map((s, i) => i === idx ? e.target.value : s) })
                            }}
                        />
                        <p className="print-only">{ s }</p>
                    </div>
                ))}
            </div>
            { Object.values(drops).reduce((acc,val) => acc + val, 0) > 0 && (
                <p><strong>Drops:</strong> { Object.keys(drops).filter((dd) => drops[dd] > 0).map((d) => drops[d] + ' ' + d).join(', ') }</p>
            ) }
            { filteredTitles.length > 0 ? (
                <TextField label="Add Title" value="" select fullWidth
                    onChange={ (e) => onChange({titles: [...(details.titles || []), e.target.value]}) }
                >{
                    filteredTitles
                        .map((t) => (
                            <MenuItem key={ t } value={ t }>{ data.titles[t].name }</MenuItem>
                        ))
                }
                </TextField>
            ) : null }
        </Card>
    )
}

export default Monster;