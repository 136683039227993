import React, { useState } from 'react';
import { 
    Card,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button,
    MenuItem,
    TextField,
} from '@material-ui/core';

import Monster from '../Monster'

import './adventure.css';
import data from '../data.json'

function Adventure() {
	const [difficulty, setDifficulty] = useState('');
	const [rank, setRank] = useState(0);
	const [monsters, setMonsters] = useState([]);
    const [dead, setDead] = useState(0);
    const [boughtTitles, setBoughtTitles] = useState([]);
    const [name, setName] = useState('');
    const [ghosts, setGhosts] = useState([]);
    const [deleteContext, setDeleteContext] = useState({});
    
    let titlePool = {};
    let dice = 0;
    let deadRemaining = dead;
    let changedGhosts = false;

    if (difficulty) {
        data.difficulties[difficulty].titles.forEach((t) => {
            titlePool[t] = 1;
        })
        dice += data.difficulties[difficulty].dice;
    }

    boughtTitles.forEach((t) => {
        titlePool[t] = (titlePool[t] ? titlePool[t] : 0) + (data.titles[t].monsters ? data.titles[t].monsters : 1)
        dice -= 1;
    })

    monsters.forEach((m) => {
        m.titles.forEach((t) => {
            titlePool[t] -= (1 * (m.qty || 1));
        });
        dice -= (1 * (m.qty || 1));
    });

    ghosts.forEach((g) => {
        deadRemaining -= data.ghosts[g.type].cost;
        if (g.titles) {
            g.titles.forEach((t) => {
                titlePool[t] -= 1;
            });
        }
    })

    while (deadRemaining >= Object.values(data.ghosts).reduce((acc, val) => Math.min(acc, val.cost), Number.POSITIVE_INFINITY)) {
        for (let ghost of Object.keys(data.ghosts).sort((a, b) => data.ghosts[b].cost - data.ghosts[a].cost )) {
            if (data.ghosts[ghost].cost <= deadRemaining) {
                changedGhosts = true;
                ghosts.push({name: data.ghosts[ghost].name, type:ghost});
                deadRemaining -= data.ghosts[ghost].cost;
                break;
            }
        }
    }

    while (Object.values(ghosts).reduce((acc, val) => acc + data.ghosts[val.type].cost, 0) > dead) {
        ghosts.pop();
        changedGhosts = true;
    }

    if (changedGhosts) setGhosts(ghosts);

	return (
		<div className="adventure">
            <Card square className="details">
                <h1>
                    <TextField label="Name" value={ name} fullWidth
                        onChange={ (e) => setName(e.target.value) }
                    />
                    <div className="print-only">{ name }</div>
                </h1>
                <div className="sidebar">
                    <TextField label="Difficulty" value={ difficulty } select fullWidth
                        onChange={ (e) => setDifficulty(e.target.value)}
                    >{
                        Object.keys(data.difficulties).map((k) => (
                            <MenuItem value={ k } key={ k }>{ data.difficulties[k].name }</MenuItem>
                        ))
                    }</TextField>
                    <TextField label="Rank" value={ rank } select fullWidth
                        onChange={ (e) => setRank(e.target.value) }
                    >{
                        data.ranks.map((_, i) => (
                            <MenuItem value={ i } key={ i }>{ i }</MenuItem>
                        ))
                    }</TextField>
                    <TextField label="Restless Dead" value={ dead } inputProps={{ type: 'number', min: 0 }} fullWidth
                        onChange={ (e) => {
                            setDead(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                            setGhosts([]);
                        }}
                    />

                    <TextField label="Buy Title" value="" select fullWidth
                        onChange={ (e) => setBoughtTitles([ ...boughtTitles, e.target.value]) }
                    >{
                        (
                            Object.keys(data.titles)
                                .filter((k) => data.titles[k].tier !== 'starter')
                        ).map((k) => (
                            <MenuItem key={ k } value={ k }>{ data.titles[k].name }</MenuItem>
                        ))
                    }</TextField>

                    <Button variant="contained" color="primary"
                        onClick={ () => {
                            setMonsters([...monsters, {titles: []}])
                        }}
                    >Buy Monster</Button>
                </div>

                <div className="monster-list">
                    { monsters.map((details, idx) => (
                        <Monster details={ details } rank={ rank } key={ idx }
                            titles={ Object.keys(titlePool).filter((t) => titlePool[t] >= 1) } 
                            onChange={ (update) => {
                                setMonsters(monsters.map((mdetails, midx) => { return (
                                    idx === midx ? { ...mdetails, ...update } : mdetails
                                )}))
                            }}
                            onDelete={ () => {
                                setDeleteContext({
                                    pending: true,
                                    type: 'Monster',
                                    callback: () => setMonsters(monsters.filter((m, i) => i !== idx))
                                })
                            }}
                        />
                    ))}{ ghosts.map((details, idx) => (
                        <Monster details={ details } rank={ rank } key={ idx } ghost
                            titles={ Object.keys(titlePool).filter((t) => titlePool[t] >= 1) } 
                            onChange={ (update) => {
                                setGhosts(ghosts.map((gdetails, gidx) => { return (
                                    idx === gidx ? { ...gdetails, ...update } : gdetails
                                )}))
                            }}
                        />
                    ))}
                </div>
            </Card>
            <Card className="footer" elevation={ 12 }>
                <span className="item">{ dice > 0 ? 'Dice to spend: ' + dice : null }</span>
                <span className="item">
                    { Object.values(titlePool).filter((q) => q > 0).length > 0 ? <>
                        Titles to spend:
                        { Object.entries(titlePool)
                            .filter((e) => e[1] > 0)
                            .map((t, idx) => {
                                const deletable = data.titles[t[0]].tier !== 'starter' && t[1] >=  (data.titles[t[0]].monsters ? data.titles[t[0]].monsters : 1);
                                return (
                                    <span key={ idx } className={ 'title' + (deletable ? ' deletable' : '') }
                                        onClick={(e) => {
                                            if (deletable) {
                                                setBoughtTitles(boughtTitles.filter((o, i) => i !== boughtTitles.indexOf(t[0])));
                                            }
                                        }}
                                    >
                                        <span className="name">{ data.titles[t[0]].name }</span>
                                        <span className="quantity">{ (t[1] > 1 ? ' x ' + t[1] : '') }</span>
                                    </span>
                                )
                            })
                        }
                    </> : null }
                </span>
            </Card>
            <Dialog
                open={ deleteContext.pending || false }
                onClose={ () => setDeleteContext({}) }
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this { deleteContext.type }?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => setDeleteContext({}) } color="primary">
                        Cancel
                    </Button>
                    <Button onClick={ () => { deleteContext.callback(); setDeleteContext({}); }} color="primary" variant="contained" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
		</div>
	);
}

export default Adventure;
